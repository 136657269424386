import React, {ReactElement} from 'react';
import Props from './typings';
import StyledTextLink from './styles';

const TextLink = ({
  href,
  title,
  target,
  rel,
  disabled,
  fontSize,
  children,
  className,
  onClick,
}: Props): ReactElement => {
  return (
    <StyledTextLink
      className={className}
      href={href}
      target={target}
      rel={rel}
      $fontSize={fontSize}
      $disabled={disabled}
      aria-label={title || children}
      onClick={onClick}
    >
      {children}
    </StyledTextLink>
  );
};

export default TextLink;
