import Image from '@/atoms/Image/Image';
import {StyledContentCard} from './styles';
import Link from 'next/link';
import Props from './typings';

const ContentCard = ({img, title, href, onClick}: Props) => {
  return (
    <StyledContentCard>
      <Image
        path={img}
        alt={`Cover image for ${title} collection`}
        width={248}
        height={165}
        retina
        responsive
      />

      <p>{title}</p>

      <Link href={href} aria-label={`Go to ${title}`} onClick={onClick} />
    </StyledContentCard>
  );
};

export default ContentCard;
