import styled, {css} from 'styled-components';
import {StyledContentProps} from './typings';
import typography from '@/theme/typography';
import {sizePx} from '@/theme/helpers/size';
import breakpoints from '@/theme/constants/breakpoints';
import {StyledColorBlock} from '@/pages/styles';
import colors from '@/theme/constants/colors';
import StyledCtaLink from '@/atoms/Links/CTA/styles';
import StyledCtaButton from '@/atoms/Buttons/CTA/styles';
import focus from '@/theme/focus';

const localZindex = {
  link: 10,
};

export const StyledCtaImmitation = styled(StyledCtaButton).attrs({as: 'span'})``;

export const StyledHomePageBanner = styled(StyledColorBlock)`
  position: relative;

  > a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: unset;
    z-index: ${localZindex.link};
    ${focus.brand(`inset ${sizePx(0)} ${sizePx(0)} ${sizePx(0)} ${sizePx(1)} ${colors.red75}`)}
  }

  &::after {
    content: '';
    position: absolute;
    width: ${sizePx(69)};
    height: ${sizePx(69)};
    background: ${colors.red100};
    border-radius: 50%;
    bottom: -69px;
    right: -69px;

    @media only screen and ${breakpoints.device.s} {
      width: ${sizePx(80)};
      height: ${sizePx(80)};
      bottom: -${sizePx(48)};
      right: unset;
    }

    @media only screen and ${breakpoints.device.l} {
      width: ${sizePx(112)};
      height: ${sizePx(112)};
      bottom: -${sizePx(56)};
    }
  }

  @media (hover: hover) and (pointer: fine) {
  &:hover{
    ${StyledCtaImmitation}{
    border-color: ${colors.red200};
    background: ${colors.red200};
    color: ${colors.white};
  }
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sizePx(16)};
  padding: ${sizePx(16)} 0;

  @media only screen and ${breakpoints.device.s} {
    flex-direction: row;
    align-items: center;
    gap: ${sizePx(12)};
    padding: ${sizePx(14)} 0;

    > * {
      flex-basis: 50%;
    }
  }

  > figure {
    border-radius: ${sizePx(8)};
    background: transparent;
  }
`;

export const StyledContent = styled.div<StyledContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: ${sizePx(6)};

  ${(props) => css`
    p:first-of-type {
      ${typography.Main}
      color: ${props.$fontColor};
    }

    p:not(:first-of-type) {
      ${typography.Body1}
      color: ${props.$fontColor};
    }
  `}

  ${StyledCtaLink} {
    margin-top: ${sizePx(6)};
  }
`;
