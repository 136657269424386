import {useDispatch, useSelector} from 'react-redux';
import Image from '@/atoms/Image/Image';
import {Props} from './typings';
import {StyledContainer, StyledContent, StyledCtaImmitation, StyledHomePageBanner} from './styles';
import colors from '@/theme/constants/colors';
import HomePageBannerSkeleton from '@/atoms/Skeletons/Banners/HomePage/HomePageBannerSkeleton';
import {useEffect} from 'react';
import {getBanner} from '@/redux/actions/bannerActions';
import {selectUserSettingsStatus} from '@/redux/reducers/userSettingsReducer';
import {STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_UPDATING} from '@/redux/actionTypes';
import {selectBannerState} from '@/redux/reducers/bannerReducer';
import {pushGAClickHomePageBanner} from '@/utils/gtm';

const colorPicker = {
  white: {
    background: colors.white,
    fontColor: colors.darkBlue100,
  },
  grey: {
    background: colors.grey100,
    fontColor: colors.darkBlue100,
  },
  matisseblue: {
    background: colors.skyBlue100,
    fontColor: colors.darkBlue100,
  },
  monetgreen: {
    background: colors.green100,
    fontColor: colors.darkBlue100,
  },
  vangoghyellow: {
    background: colors.yellow100,
    fontColor: colors.darkBlue100,
  },
  picassoblue: {
    background: colors.darkBlue100,
    fontColor: colors.white,
  },
};
const sectionTitle = 'Main page banner';

const HomePageBanner = ({country}: Props) => {
  // Hooks
  const dispatch = useDispatch();

  // Selectors
  const userSettingsStatus = useSelector(selectUserSettingsStatus);
  const {data: bannerData, error} = useSelector(selectBannerState);

  useEffect(() => {
    if (
      userSettingsStatus === STATUS_IDLE ||
      userSettingsStatus === STATUS_LOADING ||
      userSettingsStatus === STATUS_UPDATING
    )
      return;

    if (userSettingsStatus === STATUS_FAILED && bannerData) return;

    dispatch(getBanner(country));
  }, [userSettingsStatus]);

  return (
    <>
      {!error &&
        (bannerData ? (
          <StyledHomePageBanner
            $background={colorPicker[bannerData.color].background ?? colors.grey25}
            $verticalPadding="xs"
          >
            <h2 className="sr-only">{`${sectionTitle}: ${bannerData.title}`}</h2>

            <StyledContainer>
              <StyledContent
                $fontColor={colorPicker[bannerData.color].fontColor ?? colors.darkBlue100}
              >
                <p>{bannerData.title}</p>

                <p>{bannerData.description}</p>

                {bannerData.cta_link && bannerData.cta && (
                  <StyledCtaImmitation $style={'primary'}>{bannerData.cta}</StyledCtaImmitation>
                )}
              </StyledContent>

              {!!bannerData.images?.length && (
                <Image
                  path={bannerData.images[0].path}
                  width={576}
                  height={324}
                  alt={bannerData.alt_background_image_text ?? 'Cover image'}
                  loading="eager"
                  responsive
                />
              )}
            </StyledContainer>

            <a
              href={bannerData.cta_link}
              aria-label={bannerData.cta}
              onClick={() => pushGAClickHomePageBanner(bannerData.title)}
            />
          </StyledHomePageBanner>
        ) : (
          <HomePageBannerSkeleton />
        ))}
    </>
  );
};

export default HomePageBanner;
