'use client';

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import type {RecentlyViewedUIProps} from './typings';
import type Props from './typings';

import {getRecentlyViewedProducts} from '@/redux/reducers/recentlyViewedProductsReducer';
import {getRecentlyViewed} from '@/redux/actions/productActions';
import {STATUS_LOADING} from '@/redux/actionTypes';
import {pushGARecentlyViewedCardEvent} from '@/utils/gtm';
import SmallCardSkeleton from '@/atoms/Skeletons/SmallCard/SmallCardSkeleton';
import SmallCard from '@/molecules/Cards/Small/SmallCard';
import colors from '@/theme/constants/colors';
import {StyledColorBlock} from '@/pages/styles';
import {StyledRecentlyViewedHeader, StyledRecentlyViewedList} from './styles';

const ListLoadingSkeleton = () => (
  <>
    <li className="sr-only">Loading items</li>

    {Array.from({length: 6}).map((_, i) => (
      <li key={i}>
        <SmallCardSkeleton />
      </li>
    ))}
  </>
);

export const RecentlyViewedUI = ({
  data,
  isLoading,
  error,
  handleItemClick,
}: RecentlyViewedUIProps): JSX.Element | null => {
  if (error) return null;
  if (!isLoading && !data) return null;

  return (
    <StyledColorBlock $background={colors.grey25} aria-label="recently viewed products section">
      <StyledRecentlyViewedHeader>
        <h2>Recently viewed</h2>
      </StyledRecentlyViewedHeader>

      <StyledRecentlyViewedList>
        {isLoading ? (
          <ListLoadingSkeleton />
        ) : (
          data &&
          data.map(
            (
              {id, name, artist_name: artist, url, full_frontal_image: image, is_new: isNew, slug},
              index,
            ) => (
              <li key={id}>
                <SmallCard
                  href={url}
                  img={image.url}
                  title={`${name} by ${artist}`}
                  isNew={isNew}
                  onClick={handleItemClick ? () => handleItemClick(index + 1, slug) : undefined}
                />
              </li>
            ),
          )
        )}
      </StyledRecentlyViewedList>
    </StyledColorBlock>
  );
};

const RecentlyViewedSection = ({productId}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const recentlyViewed = useSelector(getRecentlyViewedProducts);

  useEffect(() => {
    dispatch(getRecentlyViewed({productId}));
  }, [productId]);

  return (
    <RecentlyViewedUI
      data={recentlyViewed.data.length ? recentlyViewed.data : null}
      isLoading={recentlyViewed.status === STATUS_LOADING}
      error={recentlyViewed.error}
      handleItemClick={typeof productId === 'undefined' ? pushGARecentlyViewedCardEvent : undefined}
    />
  );
};

export default RecentlyViewedSection;
